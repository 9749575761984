<template>
  <div class="">
    <div class="container-login100">
      <div class="wrap-login100 p-t-40 p-b-20">
        <div id="logoDiv" :class="logoShaky"><img :src="logoSrc"></div>
        <form @submit.prevent="handleLogin" class="login100-form validate-form">


          <div class="wrap-input100 validate-input m-t-40 m-b-5" data-validate="Enter email">
            <label for="username" class="focus-input100">Email</label>
            <input name="username" type="email" class="input100" autocomplete="username"  v-model="email"  @change="validateEmail()" />
          </div>
          <ul class="error-feedback" v-if="emailErrors.length > 0" v-html="emailErrors"></ul>

          <div class="wrap-input100 validate-input m-t-20 m-b-5" data-validate="Enter password">
            <label for="password" class="focus-input100">Password</label>
            <input name="password" type="password" class="input100" autocomplete="current-password" v-model="password"  @change="validatePassword()"/>
          </div>
          <ul class="error-feedback" v-if="passwordErrors.length > 0" v-html="passwordErrors"></ul>

          <div class="alert alert-danger incorrect" v-if="invalidLogin">Incorrect username or password.</div>
          <div class="alert alert-danger incorrect" v-if="underMaintenance">{{underMaintenanceMsg}}</div>

          <div class="container-login100-form-btn m-t-40">
            <button class="login100-form-btn"  :disabled="loading">
              <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
              ></span>
              <span>Login</span>
            </button>
          </div>



          <ul class="login-more p-t-30">
            <li class="m-b-8">
              <a @click="this.$root.gotoRoute({ path: '/passwordreset'})" class="txt2 clickable">Forgot Password?</a>
            </li>
            <li>
              <span class="txt1">Don’t have an account? </span><a @click="this.$root.gotoRoute({ path: '/register'})" class="txt2 clickable">Sign up</a>
            </li>
          </ul>

          <div class="version" style="">v {{this.$config.versionName}}</div>
        </form>
      </div>

    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import dingUp from '@/assets/dingup.mp3'


export default {
  name: 'Login',
  components: {

  },
  data(){

    return {
      loading: false,
      message: "",
      invalidLogin:false,
      underMaintenance:false,
      underMaintenanceMsg:'',
      logoShaky:'',
      logoSrc:'images/logo.svg',
      email:'',
      emailErrors:'',
      password:'',
      passwordErrors:'',
    };

  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    //console.log(this.$store);
    if (this.loggedIn) {
      this.$root.gotoRoute("/");
    }
  },
  methods: {
    handleLogin() {
      this.$root.playClickSound();
      this.hasValidationError = false;
      this.validateEmail();
      this.validatePassword();

      if(!this.hasValidationError){
        this.loading = true;
        const user = {};
        user.username = this.email;
        user.password = this.password;
        user.userAgent = navigator.userAgent;

        this.$store.dispatch("auth/login", user).then(
            (res) => {
              if(res.code === 'UnderMaintenance'){
                this.underMaintenanceMsg = res.msg;
                this.underMaintenance = true;
                this.loading = false;
              }else{
                this.$loginSound.play();
                if(res.emailVerified === '1'){
                  this.$router.push('/');
                }else{
                  this.$router.push('/verifyemail?email=' + this.email);
                }
              }

            },
            (error) => {
              this.loading = false;
              this.invalidLogin = true;
              console.log(error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();

            }
        );
      }


    },


    validateEmail() {
      this.invalidLogin = false;
      //console.log('email is '+this.email);
      let errors = (/\S+@\S+\.\S+/.test(this.email)) ? '' : '<li>email is not valid</li>';
      errors += this.$root.validateLength(this.email,0,100);
      this.emailErrors = errors;
      if(errors.length > 0){
        this.hasValidationError = true;
      }
    },

    validatePassword(){
      this.invalidLogin = false;
      let errors = this.$root.validateLength(this.password,0,20);
      this.passwordErrors = errors;
      if(errors.length > 0){
        this.hasValidationError = true;
      }
    },


  },

  mounted(){
    document.title = "Log In";
    setTimeout(() => {
      this.logoShaky = 'shakyLogo';
      setTimeout(() => {
        this.logoShaky = 'fadingOutLogo';
        setTimeout(() => {
          this.logoShaky = 'fadingInLogo';
          this.logoSrc = 'images/dragon2.gif';

          setTimeout(() => {
            this.logoSrc = 'images/logo.svg';
            this.logoShaky = '';
          }, 5000);
        }, 1000);
      }, 5000);

    }, 8000);
  }
}
</script>

<style scoped>


@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/poppins/Poppins-Regular.ttf)
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/poppins/Poppins-Medium.ttf)
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/poppins/Poppins-Bold.ttf)
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../fonts/poppins/Poppins-SemiBold.ttf)
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body, html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif
}

a {
  font-family: Poppins-Regular, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #666;
  margin: 0;
  transition: all .4s;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s
}

a:focus {
  outline: none !important
}

a:hover {
  text-decoration: none;
  color: #333
}

h1, h2, h3, h4, h5, h6 {
  margin: 0
}

p {
  font-family: Poppins-Regular, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.7;
  color: #666;
  margin: 0
}

ul, li {
  margin: 0;
  list-style-type: none
}

input {
  outline: none;
  border: none
}

textarea {
  outline: none;
  border: none
}

textarea:focus, input:focus {
  border-color: transparent !important
}

input:focus::-webkit-input-placeholder {
  color: transparent
}

input:focus:-moz-placeholder {
  color: transparent
}

input:focus::-moz-placeholder {
  color: transparent
}

input:focus:-ms-input-placeholder {
  color: transparent
}

textarea:focus::-webkit-input-placeholder {
  color: transparent
}

textarea:focus:-moz-placeholder {
  color: transparent
}

textarea:focus::-moz-placeholder {
  color: transparent
}

textarea:focus:-ms-input-placeholder {
  color: transparent
}

input::-webkit-input-placeholder {
  color: #adadad
}

input:-moz-placeholder {
  color: #adadad
}

input::-moz-placeholder {
  color: #adadad
}

input:-ms-input-placeholder {
  color: #adadad
}

textarea::-webkit-input-placeholder {
  color: #adadad
}

textarea:-moz-placeholder {
  color: #adadad
}

textarea::-moz-placeholder {
  color: #adadad
}

textarea:-ms-input-placeholder {
  color: #adadad
}

.m-t-20{
  margin-top:20px;
}

button {
  outline: none !important;
  border: none;
  background: 0 0
}

button:hover {
  cursor: pointer
}

iframe {
  border: none !important
}

.txt1 {
  font-family: Poppins-Regular, Arial, sans-serif;
  font-size: 15px;
  color: #999;
  line-height: 1.5
}

.txt2 {
  font-family: Poppins-Regular, Arial, sans-serif;
  font-size: 15px;
  color: #57b846;
  line-height: 1.5
}

.limiter {
  width: 100%;
  margin: 0 auto
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #fff;
  padding:50px 20px;
}

.wrap-login100 {
  width: 390px;
  background: #fff
}

.login100-form {
  width: 100%
}


.login100-form-avatar {
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto
}

.login100-form-avatar img {
  width: 100%
}

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #d9d9d9;
}

.input100 {
  font-family: Poppins-SemiBold, Arial, sans-serif;
  font-size: 18px;
  color: #555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 52px;
  background: 0 0;
  padding: 0 5px
}

.focus-input100 {
  font-family: Poppins-Medium, Arial, sans-serif;
  color: #999;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;

}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
  background: #57b846
}


.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center
}

.login100-form-btn {
  font-family: Poppins-Medium, Arial, sans-serif;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background-color: #57b846;
  border-radius: 25px;
  box-shadow: 0 10px 30px 0 rgba(87, 184, 70, .5);
  -moz-box-shadow: 0 10px 30px 0 rgba(87, 184, 70, .5);
  -webkit-box-shadow: 0 10px 30px 0 rgba(87, 184, 70, .5);
  -o-box-shadow: 0 10px 30px 0 rgba(87, 184, 70, .5);
  -ms-box-shadow: 0 10px 30px 0 rgba(87, 184, 70, .5);
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s
}

.login100-form-btn:hover {
  background-color: #333;
  box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
  -moz-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
  -webkit-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
  -o-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5);
  -ms-box-shadow: 0 10px 30px 0 rgba(51, 51, 51, .5)
}

.validate-input {
  position: relative
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 10px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  pointer-events: none;
  font-family: Poppins-Regular;
  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .4s;
  -o-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s
}



.alert-validate:hover:before {
  visibility: visible;
  opacity: 1
}

@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1
  }
}

.login-more{
  margin-top:40px;
}

.login-more li {
  position: relative;
  padding-left: 16px
}

.login-more li::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ccc;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0
}






#logoDiv{
  margin:40px 0 0 0 ;
  padding:10px 20px;
}

#logoDiv{
  text-align: center;
}

#logoDiv img{
  width:200px;

}

.shakyLogo{
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }


}

.fadingOutLogo{
  animation: fadingOutLogo 5s;
  animation-iteration-count: 1;
}
@keyframes fadingOutLogo {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadingInLogo{
  animation: fadingInLogo 2s;
  animation-iteration-count: 1;
}
@keyframes fadingInLogo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.m-b-35 {
  margin-bottom: 35px;
}
.m-t-40 {
  margin-top: 40px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.p-t-60 {
  padding-top: 60px;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.error-feedback-div{
  margin-bottom:5px;
}

.alert-div{
  margin-top:40px;
}

.spinner-border{
  margin-right:5px;
}

.incorrect{
  margin-top:20px;
  padding:3px 5px;
}

.version{
  font-family: Poppins-Medium, Arial, sans-serif;
  color:#999;
  text-align: center; margin-top:40px;font-size:10px;
}

</style>
